import React from 'react'
import './packages.scss';

const Packages = () => {
    return (
        <div id="packages" className="container">
          <div className="section__margin">
            <h1 className="header">Paketi</h1>
          </div>
          <div className="card__container section__margin">
            <div className="card">
              <div className="card__content">
                <h3 className="card__header">Svadba</h3>
                <ul className="card__list">
                <li>Priprema</li>
                <hr></hr>
                <li>Venčanje</li>
                <hr></hr>
                <li>Svadbeno veselje</li>
                <hr></hr>
                <li>Session na željenoj lokaciji</li>
                <hr></hr>
                <li>Obrađene fotografije na usb-u</li>
                <hr></hr>
                <li>Snimak veselja do 4h</li>
                <hr></hr>
                <li>2-5min. video</li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card__content">
                <h3 className="card__header">Venčanje</h3>
                <ul className="card__list">
                <li>Venčanje</li>
                <hr></hr>
                <li>Mini session na dan venčanja</li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card__content">
                <h3 className="card__header">Rođendan</h3>
                <ul className="card__list">
                <li>Proslava</li>
                <hr></hr>
                <li>Mini session na dan rođendana</li>
                <hr></hr>
                <li>Snimak veselja do 3h</li>
                <hr></hr>
                <li>2-5min. video</li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card__content">
                <h3 className="card__header">Couple</h3>
                <ul className="card__list">
                <li>Session na željenoj lokaciji</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Packages
