import React, { useRef,useState } from 'react';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import '../contact/contact.css'
import logo from '../../assets2/newLogo.png'
import Popup from '../popup/Popup';

export const Contact = () => {
  const form = useRef();
  const [show, setShow] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.init('-EAf1GZOLXSEP-P5h')
    emailjs.sendForm('service_dnq9rm5', 'template_1rr6g02', form.current, '-EAf1GZOLXSEP-P5h')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      document.getElementById('name').value='';
      document.getElementById('email').value='';
      document.getElementById('subject').value='';
      document.getElementById('message').value='';

      setShow(true);
  };
    // const navigate = useNavigate();

  return (
    <div id="contact" className='contact-main section__margin'>
      <div>
          <h1 className="header">Kontakt</h1>
      </div>
      <div className="contact-container">
        <img className="logo" src={logo}/>
        <div className="right-col">
          <p>
            Voleo bih da budem deo Vašeg veselja i pravi izbor 
            za Vaše fotografije. Vi znate ko sam ja i mnogo bi mi značilo da i ja upoznam Vas. 
            Podelite sa mnom što više o Vama i Vašim divnim 
            planovima. Željno iščekujem Vašu poruku! Hvala vam! 
          </p>
          <form ref={form} id="contact-form" onSubmit={sendEmail}>
            <div className="contact-info">
              <div>
                <label htmlFor="name">Ime i prezime</label>
                <input id="name" type="text" name="user_name" placeholder="Ime i prezime" required/>
              </div>
              <div>
                <label htmlFor="email">Email Adresa</label>
                <input id="email" type="email" name="user_email" placeholder="Email adresa" required/>
              </div>
            </div>
            <label htmlFor="subject">Naslov i datum</label>
            <input id="subject" type="text"  name="user_subject" placeholder="Naslov poruke i datum" required/>
            <label htmlFor="message">Poruka</label>
            <textarea id="message" rows="6" placeholder="Tekst poruke" name="message" required="required"></textarea>
            <input className="btn" type="submit" value="Pošalji" />
          </form>
          <Popup onClose={()=>setShow(false)} show={show}></Popup>
        </div>
      </div>
    </div>
  );
};

export default Contact