import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import AboutMe from './components/about me/AboutMe';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import NewSlider from './components/new slider/NewSlider';
import Packages from './components/packages/Packages';
import MaintenanceImage from './assets/Maintenance.jpg';

const App = () => {
    const main = useRef(null);
    const nav = useRef(null);
    const [stickyHeader, updateStickyHeader] = useState(false);
    const [isMaintenance, setIsMaintenance] = useState(true); // Set this to true for maintenance mode

    useEffect(() => {
        // on mount query and update refs value if the app is not in maintenance mode
        if (!isMaintenance) {
            main.current = document.querySelector('.main');
            nav.current = document.querySelector('.mmp__navbar-links_container');

            window.onscroll = function () {
                if (main.current && nav.current) {
                    if (window.pageYOffset + 3100 > (main.current.offsetHeight - nav.current.offsetHeight)) {
                        updateStickyHeader(true);
                    } else {
                        updateStickyHeader(false);
                    }
                }
            }
        }

        // Cleanup the scroll event on unmount
        return () => {
            window.onscroll = null;
        };
    }, [isMaintenance]);

    return (
        <div className='main'>
            {isMaintenance ? (
                // Render maintenance image
                <div style={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Full viewport height
                    width: '100vw',  // Full viewport width
                    overflow: 'hidden',
                    margin: '0',
                    padding: '0',
                }}>
                    <img 
                        src={MaintenanceImage} 
                        alt="Maintenance" 
                        style={{ 
                            maxWidth: '100%', 
                            maxHeight: '100%', 
                            objectFit: 'cover' // Ensures the image covers the screen without distortion
                        }} 
                    />
                </div>
            ) : (
                // Render the rest of the components
                <>
                    <Navbar sticky={stickyHeader}></Navbar>
                    <AboutMe></AboutMe>
                    <NewSlider></NewSlider>
                    <Packages></Packages>
                    <Contact></Contact>
                    <Footer></Footer>
                </>
            )}
        </div>
    );
}

export default App;
