import React from 'react';
import fb from '../../assets2/minifb.png'
import instagram from '../../assets2/miniinsta.png'
import './footer.css'

const Footer = () => {
  return (
    <div className='footer-main'>
        <a href="tel:+381640059213">+(381) 64/005-92-13</a>
        <p>info@markomitrovicweddings.com</p>
        <a href="https://www.facebook.com/marko.photografie"><img src={fb}/></a>
        <a href="https://www.instagram.com/marko.mitrovic.weddings/"><img src={instagram}/></a>
    </div>
  )
}

export default Footer;
