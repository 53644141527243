const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context("../src/assets/", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1].default);

var imageArray = [];

images.map((image) =>{
  var obj = {}
  obj.image=image;
  imageArray.push(obj)
})

export default imageArray;

