import { useState, useEffect } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import  imageArray  from "../../images";
import "./newslider.scss";

const NewSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = imageArray.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <div id="gallery" className="section__margin">
      <h1 className="header">Galerija</h1>
        <div className="slider">
          {imageArray.map((slide, index) => {
            return (
            <div
              className={index === currentSlide ? "slide current" : "slide"}
              key={index}
            >
              {index === currentSlide && (
              <div>
                  <AiOutlineLeft className="arrow prev" onClick={prevSlide} />
                  <AiOutlineRight className="arrow next" onClick={nextSlide} />
                  <img src={slide.image} alt="slide" className="image" />    
              </div>
              )}
            </div>
            );
        })}
        </div>
    </div>
  );
};

export default NewSlider;