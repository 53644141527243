import React, { useEffect, useState } from 'react'
import './popup.scss'

const Popup = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => {props.onClose()},5000);
        return () => clearTimeout(timer);
    },[props.show])

    if(!props.show) {
        return null
    }

    return(
        <div className='mmp__popup_modal' onClick={props.onClose}>
            {/* stopPropagation if we click outside of modal form it will close */}
            <div className='mmp__popup_modal-content' onClick={e => e.stopPropagation()}> 
                <div className='mmp__popup_modal-header'>
                    <h2 className='mmp__popup_modal-title'>Hvala!</h2>
                </div>
                <div className='mmp__popup_modal-body'>
                    <p>Primio sam Vasu poruku!</p>
                </div>
                <div className='mmp__popup_modal-footer'>
                    <button className='' onClick={props.onClose}>Close</button>
                </div>
            </div>
        </div>
    )
}
export default Popup;