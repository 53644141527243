import React from 'react'
import './aboutMe.css';
import myPhoto from '../../assets2/MMP_0007.jpg'

const AboutMe = () => {
    return (
        <div id="aboutme" className="main-container"> 
            <div className="section__margin">
                <h1 className="header">O meni</h1>
            </div>
            <div className="aboutme-container section__margin">
                <div className="left-cont">
                    <img className="myPhoto" src={myPhoto}/>
                </div>
                <div className="right-cont">
                    <h2>Zdravo,</h2>
                    <br></br>
                    <br></br>
                    <p>
                        drago mi je da ste ovde. Našli ste osobu sa kojom želite da provedete ostatak svog života?
                        Vašu najveću ljubav i najboljeg prijatelja? Sjajno! Želim to proslaviti sa Vama. 
                    </p>
                    <p>
                        Ja sam Marko, fotograf emocija i ljudi koji su u ljubavi.
                        Obožavam beležiti emocije i Vaše uspomene. Pričajte mi o Vašoj priči i Vašim
                        planovima. Radujem se Vašem pozivu!
                    </p>                   
                </div>
            </div>
        </div>
    )
}

export default AboutMe
