import React, {useState} from 'react'
import './navbar.css'
import logo from '../../assets2/mininewlogo.png'
import mainPhoto from '../../assets/MMP_0129.jpg'
import minilogo from '../../assets2/newminilogowhite.png'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const Navbar = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false); //hooks
    return (
        <div>
            <div className='navbar'>
                <figure className='mmp__navbar-links_container'>
                    <figcaption className={`nav ${props.sticky ? 'top-nav' : 'bottom-nav'}`}>
                        <a href="#"><img href="#" className="logoo" src={props.sticky ? logo : minilogo} alt="logo"/></a>
                        <p><a href="#aboutme">O meni</a></p>
                        <p><a href="#gallery">Galerija</a></p>
                        <p><a href="#packages">Paketi</a></p>
                        <p><a href="#contact">Kontakt</a></p>
                    </figcaption>
                    <img src={mainPhoto} className="responsive" alt="main"/>
                </figure>
            </div> 
            <div className="gpt3__navbar-menu">
               <img src={mainPhoto} className="responsive2" alt="main"/> 
               <div className='navbar-menu'>
               <a href="#"><img href="#" className="logo-menu" src={logo} alt="logo"/></a>
                {toggleMenu
                ? <RiCloseLine className='sandwich' color="#000" size={35} onClick={ () => setToggleMenu(false)}/>
                : <RiMenu3Line className='sandwich' color="#000" size={35} onClick={ () => setToggleMenu(true)}/>
                }
                </div>
                    {toggleMenu && (
                        <div className="gpt3__navbar-menu_container scale-up-center">
                            <div className="gpt3__navbar-menu_container-links">
                                <p><a href="#aboutme">O meni</a></p>
                                <p><a href="#gallery">Galerija</a></p>
                                <p><a href="#packages">Paketi</a></p>
                                <p><a href="#contact">Kontakt</a></p>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Navbar
